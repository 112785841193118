import React, { useEffect, useState } from 'react';
import {
  StudentLead_Activities,
  StudentLead_Status,
  // temp_studentLead_Status,
} from './../../../Util/Constants';
import { Button } from '@mui/material';
import { Countries } from '../../../Util/Util';
import Agent from '../../../api/agent';

const TopNavbar = ({
  branchOfficeId,
  upfirstName,
  upLastName,
  updateCitizenship,
  users,
  assessmentTeam,
  teamLeader,
  selectedLeadStatus,
  setSelectedLeadStatus,
  selectedLeadActivity,
  setSelectedLeadActivity,
  showConvertButton,
  handleConvertButtonClick,
  handleUpdateStudentProfile
}) => {
  const numStatus = parseInt(selectedLeadStatus);
  const numActivity = parseInt(selectedLeadActivity);
  const [offices, setBranchOffices] = useState([]);
  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setBranchOffices);
  }, []);

  // custom function to find the name from an array based on ID
  const findNameById = (id, array) => {
    const item = array.find((item) => item.id === id);
    return item ? item.name : '';
  };

  const assessmentTeamName = findNameById(assessmentTeam, users);

  const citizenship = findNameById(updateCitizenship, Countries);
  const branchOfficeName = findNameById(branchOfficeId, offices);

  const valueGetter=(stu_value)=>{
    for(const val in StudentLead_Status) {
      if (StudentLead_Status.hasOwnProperty.call(StudentLead_Status, val)) {
        const element = StudentLead_Status[val];
        if (stu_value==element.id) {
          return stu_value
        }
      }
    }
   }
   const filtered_StudentLead_Status=StudentLead_Status.filter(stu=>{
      return valueGetter(stu.id)
   })

  return (
    <nav className="flex flex-row-reverse items-center justify-between bg-sky-200 py-2 pr-2 shadow-sm">
      <div className="flex items-center space-x-2 gap-2">
        <div className="flex flex-col space-y-2">
          <Button
            onClick={() => {
              // Trigger the reload when the button is clicked
              handleConvertButtonClick();
            }}
            variant="contained"
            color="primary"
            className="px-6 mt-6 py-2 bg-blue-800 text-white rounded"
            disabled={showConvertButton === false || numStatus !== 5}
          >
            Convert Lead
          </Button>
        </div>

        <div className="flex flex-col space-y-2">
          <label
            htmlFor="leadStatus"
            className="text-sm font-medium text-gray-600"
          >
            Lead Status:
          </label>
          <select
            id="leadStatus"
            className="input input-bordered text-gray-700 border-gray-300 hover:border-gray-400"
            value={numStatus}
            onChange={(e) => setSelectedLeadStatus(e.target.value)}
          >
            {filtered_StudentLead_Status.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
{/* 
        <div className="flex flex-col space-y-2">
          <label
            htmlFor="leadActivity"
            className="text-sm font-medium text-gray-600"
          >
            Lead Activity:
          </label>
          <select
            id="leadActivity"
            className="input input-bordered text-gray-700 border-gray-300 hover:border-gray-400"
            value={numActivity}
            onChange={(e) => setSelectedLeadActivity(e.target.value)}
          >
            {StudentLead_Activities.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div> */}
        <div  className="relative">
    <Button className=' mt-8 font-semibold text-gray-200 px-3 bg-indigo-600 hover:bg-indigo-500 rounded-md shadow-md' onClick={handleUpdateStudentProfile}>Save</Button>
    </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="ml-4">
          <p className="text-base font-medium text-gray-800">
            <span className="mr-2 font-semibold text-blue-600">Full Name:</span>{' '}
            {upfirstName} {upLastName}
          </p>
          <p className="text-base font-medium text-gray-800">
            <span className="mr-2 font-semibold text-blue-600">Citizenship:</span>{' '}
            {citizenship}
          </p>
        </div>
        <div className="ml-4">
          <p className="text-base font-medium text-gray-800">
            <span className="mr-2 font-semibold text-blue-600">
              Team Leader:
            </span>{' '}
            {teamLeader}
          </p>
          <p className="text-base font-medium text-gray-800">
            <span className="mr-2 font-semibold text-blue-600">
              Assessment:
            </span>{' '}
            {assessmentTeamName}
          </p>
          <p className="text-base font-medium text-gray-800">
            <span className="mr-2 font-semibold text-blue-600">Branch:</span>{' '}
            {branchOfficeName}
          </p>
        </div>
      </div>
    </nav>
  );
};

export default TopNavbar;
