/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';
import enGB from 'date-fns/locale/en-GB'; // the locale you want
import DatePicker, { registerLocale } from 'react-datepicker';
import { startOfDay, addHours, addMonths } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import { format } from 'date-fns';

import StudentAppList from '../Students/Student/ApplicationList/ApplicationList';
import { ApplicationAssignment } from '../Common/Widgets/ApplicationAssignment';
registerLocale('en-GB', enGB); // register it with the name you want
import LoadingView from '../Common/Loading/LoadingView';
import * as actions from '../../store/actions/index';
import 'react-datepicker/dist/react-datepicker.css';
import MyTooltip from '../Common/Tooltip/MyTooltip';
import { Search } from '../Common/Widgets/Search';
import AddMessage from '../Messages/AddMessage';
import { Countries } from '../../Util/Util';
import { connect } from 'react-redux';

import {
  APPLICATION_FILE_STATUSES,
  COMPLIANCE_STATUS,
  GENDER,
  LEADCHANNELS,
  LEADORIGINS,
  MUI_LICENSE_KEY,
  STUDENTSOURCES,
  intakeYearList,
  yearList,
} from '../../Util/Constants';
import {
  LicenseInfo,
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbar,
  GridPagination,
} from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import {
  APPLICATION_STATUSES,
  STUDY_LEVELS,
  INTAKES,
} from '../../Util/Constants';
import Agent from '../../api/agent';
import Description from '@mui/icons-material/Description';
import ApplicationNotes from './ApplicationNotes';

function CustomToolbar(show, isInternal) {
  return (
    <GridToolbarContainer>
      {isInternal && <GridToolbarColumnsButton />}
      {isInternal && <GridToolbarFilterButton />}
      <GridToolbarDensitySelector />
      {show && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}

const Applications = ({
  users,
  loadUsers,
  role,
  permissions,
  isInternal,
  agentId = 0,
  userName,
  partners,
  loadPartners
}) => {
  // const classes = useStyles();

  const [searchParams] = useSearchParams();
  const sts = searchParams.get('sts');
  const [selectionModel, setSelectionModel] = useState([]);
  const [openAddMessage, setOpenAddMessage] = useState(false);
  const [to, setTo] = useState();
  const [ready, setReady] = useState(false);
  const [readyEdit, setReadyEdit] = useState(false);
  const [applications, setApplications] = useState([]);
  const [name, setName] = useState('');
  const [applicationRef, setApplicationRef] = useState('');
  const [intake, setIntake] = useState(0);
  const [status, setStatus] = useState(sts ? sts : '');
  const [noOfDays, setNoOfDays] = useState(sts ? 0 : '');
  const [institution, setInstitution] = useState('');
  const [id, setId] = useState('');
  const [studentId, setStudentId] = useState('');
  //const [ownApplication, setOwnApplication] = useState(role.isApplicationTeam);
  const [loading, setLoading] = useState(false);
  const [applicationLoaded, setApplicationLoaded] = useState(false);
  const [page, setPage] = useState(1);
  // const pageSize = 50;
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(800);
  const [totalPages, setTotalPages] = useState();
  const [totalrows, setTotalRows] = useState(0);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [openAgent, setOpenAgent] = useState(false);
  const [openAppTeam, setOpenAppTeam] = useState(false);
  const [openResCountry, setOpenResCountry] = useState(false);
  const [openBdmTeam, setOpenBdmTeam] = useState(false);
  const [cosmosAdminUsers, setCosmosAdminUsers] = useState([]);
  const [cosmosAppTeamUsers, setCosmosAppTeamUsers] = useState([]);
  const [cosmosBdmUsers, setCosmosBdmUsers] = useState([]);
  const [AgentList, setAgentList] = useState([]);
  const adminLoading = openAdmin && cosmosAdminUsers.length === 0;
  const agentLoading = openAgent && AgentList.length === 0;
  const appTeamLoading = openAppTeam && cosmosAppTeamUsers.length === 0;
  const appBdmLoading = openBdmTeam && cosmosBdmUsers.length === 0;
  const [cosmosAdmin, setCosmosAdmin] = useState(null);
  const [cosmosAppTeam, setCosmosAppTeam] = useState(null);
  const [cosmosBdmTeam, setCosmosBdmTeam] = useState(null);
  const [start, setStart] = useState(addMonths(new Date(), -1));
  const [end, setEnd] = useState(new Date());
  const [dateType, setDateType] = useState(1);
  const [appPriorityId, setAppPriorityId] = useState(0);
  const [citizenship, setCitizenship] = useState(null);
  const [countryOfResidance, setCountryOfResidance] = useState(null);
  const [destination, setDestination] = useState(null);
  const bdmRef = useRef();
  const appTeamRef = useRef();
  const adminRef = useRef();
  const agentRef = useRef();
  const citizenshipRef = useRef();
  const countryOfResidanceRef = useRef();
  const [reset, setReset] = useState();
  const [appToEdit, setAppToEdit] = useState();
  const [intakeYear, setIntakeYear] = useState(0);
  const [referralAssignId, setReferralAssignId] = useState(0);
  const [agentFilterId, setAgentFilterId] = useState(null);
  const [
    filteredFollowAndAssessmentTeams,
    setFilteredFollowAndAssessmentTeams,
  ] = useState([]);
  const [leadOrigin, setLeadOrigin] = useState(0);
  const [leadSource, setLeadSource] = useState(0);
  const [leadChannel, setLeadChannel] = useState(0);
  const [studyLevelId, setStudyLevelId] = useState(0);
  const [applicationPartnerId, setApplicationPartnerId] = useState(0);
  const [assessmentTeam, setAssessmentTeam] = useState(0);
  const [followupTeamId, setFollowUpTeam] = useState(0);
  const [branchOfficeId, setBranchOfficeId] = useState(0);
  const [studentType, setStudentType] = useState(0);
  const [offices, setOffices] = useState([]);
  const [openAddNote, setOpenAddNote] = useState(false);
  const [msgName, setMsgName] = useState();
  const [appId, setAppId] = useState();
  const [notes, setNotes] = useState([]);
  const [events, setEventList] = useState([]);
  const [eventId, setEventId] = useState(0);
  const [complianceStatus, setComplianceStatus] = useState(0);
  const [referralList, setReferralList] = useState([{}]);
  const [tempCountry, setTempCountry]=useState([]);

  useEffect(() => {
    const filteredFollowAndAssessmentTeams = users.filter(
      (user) => !user.isDisabled && [1, 2, 3].includes(user.roleId)
    );

    setFilteredFollowAndAssessmentTeams(filteredFollowAndAssessmentTeams);
  }, [users]);
  
  useEffect(() => {
    setTempCountry(Countries);
  }, [Countries]);
  useEffect(() => {
    Agent.Events.getEventList().then((res) => {
      res.sort(function (a, b) {
        if (a.id > b.id) {
          return -1;
        }
        if (a.id < b.id) {
          return 1;
        }
        return 0;
      });
      setEventList(res);
    });
  }, []);

  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setOffices);
  }, []);

  useEffect(() => {
    if (isInternal) {
      if (users.length == 0) {
        loadUsers()
          .then(() => {
            //  getData();
          })
          .catch((error) => {
            console.log(error);
            //debugger;
          });
      } else {
        getData(true);
      }
    } else {
      getData(true);
    }
  }, [users, pageNumber]);

  useEffect(() => {
    if (reset && isInternal) {
      bdmRef.current.value = '';
      appTeamRef.current.value = '';
      adminRef.current.value = '';
      agentRef.current.value='';
      //citizenshipRef.current.value = '';
    }
  }, [reset]);

  const resetFilter = () => {
    setReset(true);
    setName('');
    setApplicationRef('');
    setId('');
    setStudentId('');
    setIntake(0);
    setStatus('');
    setNoOfDays('');
    setInstitution('');
    setStart(addMonths(new Date(), -1));
    setEnd(new Date());
    setDateType(1);
    setAppPriorityId(0);
    setCosmosAdmin(null);
    setCosmosAppTeam(null);
    setCosmosBdmTeam(null);
    setCitizenship(null);
    setDestination(null);
    setCountryOfResidance(null);
    setAssessmentTeam(0);
    setFollowUpTeam(0);
    setStudentType(0);
    setLeadOrigin(0);
    setLeadSource(0);
    setLeadChannel(0);
    setStudyLevelId(0);
    setIntakeYear(0);
    setEventId(0);
    setApplicationPartnerId(0);
    setComplianceStatus(0);
    //setOwnApplication(false);
    getData(false);
    setReferralAssignId(0);
    setAgentFilterId(null);
  };

  const applyFilter = () => {
    getData(true);
    setPageNumber(1);
    setReset(false);
  };

  useEffect(() => {
    if (partners.length == 0) {
      loadPartners();
    }
  }, [partners]);

  useEffect(() => {
    let activeBDM = true;
    if (!appBdmLoading) {
      return undefined;
    }
    (async () => {
      if (activeBDM) {
        if (cosmosBdmUsers.length > 0) setCosmosBdmUsers(cosmosBdmUsers);
        else {
          Agent.Cosmos.getUsers()
            .then((usrs) => {
              setCosmosBdmUsers(
                usrs.filter((item) => {
                  return (
                    item.usertype == 1 ||
                    item.usertype == 2 ||
                    item.usertype == 3
                  );
                })
              );
              setCosmosAppTeamUsers(
                usrs.filter((item) => {
                  return item.usertype == 7 || item.usertype == 8;
                })
              );
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    })();

    return () => {
      activeBDM = false;
    };
  }, [appBdmLoading]);

  useEffect(() => {
    let activeAdmin = true;
    if (!adminLoading) {
      return undefined;
    }
    (async () => {
      if (activeAdmin) {
        if (cosmosAdminUsers.length > 0) setCosmosAdminUsers(cosmosAdminUsers);
        else {
          Agent.Cosmos.getUsers()
            .then((usrs) => {
              setCosmosAdminUsers(
                usrs.filter((item) => {
                  return (
                    (item.usertype == 1 ||
                      item.usertype == 2 ||
                      item.usertype == 3) &&
                    !item.isdisabled
                  );
                })
              );

              setCosmosAppTeamUsers(
                usrs.filter((item) => {
                  return (
                    (item.usertype == 7 || item.usertype == 8) &&
                    !item.isdisabled
                  );
                })
              );
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    })();

    return () => {
      activeAdmin = false;
    };
  }, [adminLoading]);

  useEffect(() => {
    let activeAppTeam = true;
    if (!appTeamLoading) {
      return undefined;
    }
    (async () => {
      if (activeAppTeam) {
        if (cosmosAppTeamUsers.length > 0)
          setCosmosAppTeamUsers(cosmosAppTeamUsers);
        else {
          Agent.Cosmos.getUsers()
            .then((usrs) => {
              setCosmosAdminUsers(
                usrs.filter((item) => {
                  return (
                    item.usertype == 1 ||
                    item.usertype == 2 ||
                    item.usertype == 3
                  );
                })
              );
              setCosmosAppTeamUsers(
                usrs.filter((item) => {
                  return item.usertype == 7 || item.usertype == 8;
                })
              );
            })
            .catch((error) => {
              console.log(error);
              // debugger;
            });
        }
      }
    })();

    return () => {
      activeAppTeam = false;
    };
  }, [appTeamLoading]);

  useEffect(() => {
    let activeAgent = true;
    if (!agentLoading) {
      return undefined;
    }
    (async () => {
      if (activeAgent) {
        if (AgentList.length > 0)
          setAgentList(AgentList);
        else {
          Agent.Agents.list()
            .then((usrs) => {
              setAgentList(usrs);
            })
            .catch((error) => {
              console.log(error);
              // debugger;
            });
        }
      }
    })();

    return () => {
      activeAgent = false;
    };
  }, [agentLoading]);

  const addMessage = (adminId, name) => {
    setTo({ ...to, id: adminId, name: name });
    setOpenAddMessage(true);
  };

  const showEditOptions = (row) => {
    setAppToEdit(row);
    if (users.length == 0) {
      loadUsers()
        .then(() => {
          setReadyEdit(true);
        })
        .catch((error) => {
          console.log(error);
          debugger;
        });
    } else {
      setReadyEdit(true);
    }
  };
  useEffect(() => {
    Agent.Referral.List().then((res) => {
      setReferralList(res);
    });
  }, []);
 
  const getParams = (filter) => {
    const params = new URLSearchParams();
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', pageSize.toString());
    params.append('name', filter ? name.trim() : '');
    params.append('id', filter ? id.trim() : '');
    if (filter && intake) {
      params.append('intake', intake.toString());
    }
    if (filter && status) {
      params.append('status', status.toString());
    }
    // status is handled differently because status can be passed in url parameter
    if (filter && cosmosAdmin) {
      params.append('adminId', cosmosAdmin ? cosmosAdmin.id : '');
    }
    if (filter && cosmosAppTeam) {
      params.append('appTeamId', cosmosAppTeam.id.toString());
    }
    if (filter && cosmosBdmTeam) {
      params.append('BusinessDevAppUserDetailsId', cosmosBdmTeam.appUserDetailsId.toString());
    }
    if (filter && institution) {
      params.append('institution', institution.trim());
    }
    if (filter && studentId) {
      params.append('studentId', studentId.trim());
    }
    if (filter && eventId) {
      params.append('eventId', eventId);
    }
    if (agentId > 0) {
      params.append('agentId', agentId.toString());
    }
    if (filter) {
      params.append('PartnerReferralId', referralAssignId);
    }
    if (filter) {
      params.append('StudyLevelId', studyLevelId);
    }
    if (filter) {
      params.append('ApplicationPartnerId', applicationPartnerId);
    }
    if (filter && agentFilterId) {
      params.append('PartnerAgentId', agentFilterId ? agentFilterId.id : '');
    }
    params.append('lastNoOfDays', noOfDays.toString());
    if (filter) {
      if (start) {
        params.append('startDate', new Date(start).toDateString());
      }
    } else {
      params.append('startDate', addMonths(new Date(), -1).toDateString());
    }
    if (filter) {
      if (end) {
        params.append('endDate', new Date(end).toDateString());
      }
    } else {
      params.append('endDate', new Date().toDateString());
    }
    params.append('dateType', dateType);
    // if (citizenship && filter) {
    //   params.append('citizenship', citizenship.id.toString());
    // }

    if (complianceStatus && filter) {
      params.append('ComplianceStatus', complianceStatus);
    }
    if (branchOfficeId && filter) {
      params.append('branchOfficeId', branchOfficeId);
    }
    if (countryOfResidance && filter) {
      params.append('CountryOfResidence', countryOfResidance.id);
    }
    if (destination && filter) {
      params.append('DestinationCountryId', destination.id.toString());
    }
    if (intakeYear && filter) {
      params.append('Year', intakeYear);
    }
    if (assessmentTeam && filter) {
      params.append('AssessmentTeamId', assessmentTeam);
    }
    if (followupTeamId && filter) {
      params.append('FollowupTeamId', followupTeamId);
    }
    if (leadSource && filter) {
      params.append('Source', leadSource);
    }
    if (leadChannel && filter) {
      params.append('Channel', leadChannel);
    }
    if (leadOrigin && filter) {
      params.append('Origin', leadOrigin);
    }
    if (studentType && filter) {
      params.append('StudentType', studentType);
    }
    if (applicationRef && filter) {
      params.append('ApplicationRef', applicationRef);
    }
    if (filter) {
      params.append('ApplicationPriorityId', appPriorityId);
    }
    
    return params;
  };

  const getData = async (filter = false) => {
    setLoading(true);
    try {
      const params = getParams(filter);
      const applications = await Agent.Applications.list(params);
      const { pagination, data } = applications;
      getRows(data);
      console.log('data', data);
      setTotalPages(pagination.totalPages);
      setTotalRows(pagination.totalItems);
    } catch (error) {
      console.error(error);
    }
  };
  const handleIntakeYearChange = (event, value) => {
    setIntakeYear(value ? value.year : 0);
  };
  const getRows = (rows) => {
    let applicationRows = [];

    rows?.map((application) => {
      applicationRows.push({
        id: application.id,
        applicationRef: application.applicationRef,
        studentId: application.studentId,
        adminAppUserId: application.adminAppUserId,
        admin: application.adminName,
        citizenship:
          application.citizenship && application.citizenship > 0
            ? Countries.find(({ id }) => id === application.citizenship)?.name
            : '',
        destination:
          application.countryId && application.countryId > 0
            ? Countries.find(({ id }) => id === application.countryId)?.name
            : '',
        residence:
          application.countryOfResidence && application.countryOfResidence > 0
            ? Countries.find(({ id }) => id === application.countryOfResidence)?.name
            : '',
        applicationPriorityId:application.applicationPriorityId,
        admissionTeamAppUserId: application.admissionTeamAppUserId,
        appTeam: application.applicationTeamName,
        eventName: application.eventName,
        status: APPLICATION_STATUSES.find(({ id }) => id == application.status)?.type,
        statusId: application.status,
        appliedOn: format(new Date(application.appliedOn), 'dd/MM/yy'),
        appliedOnActual:
          application.appliedOnActual == null
            ? ''
            : format(new Date(application.appliedOnActual), 'dd/MM/yy'),
        lastActionedOn: format(
          new Date(application.lastActionedOn),
          'dd/MM/yy'
        ),
        name: `${application.firstName} ${application.lastName}`,
        university: application.universityName,
        course: application.courseName,
        courseDuration:
          application.courseStartDate == null
            ? ''
            : format(new Date(application.courseStartDate), 'dd/MM/yy') +
              ' - ' +
              format(new Date(application.courseEndDate), 'dd/MM/yy'),
        intake: INTAKES.find(({ id }) => id == application.intake)?.type,
        studyLevel: STUDY_LEVELS.find(
          ({ id }) => id == application.studyLevelId
        )?.type,
        agentCompanyName: application.agentCompanyName,
        referralName: application.referralName,
        bdm:
          application.businessDevAppUserDetailsId && users.length > 0
            ? users.find(
                ({ id }) => id === application.businessDevAppUserDetailsId
              )?.name
            : '',
        complianceStatus: application.complianceStatus,
        complianceStatusName: application.complianceStatus
          ? COMPLIANCE_STATUS.find(
              ({ id }) => id === application.complianceStatus
            )?.type
          : '',
        conversionTeamId: application.conversionTeamId,
        conversionTeam:
          application.conversionTeamId && users.length > 0
            ? users.find(({ id }) => id === application.conversionTeamId)?.name
            : '',
        complianceTeamId: application.complianceTeamId,
        complianceTeam:
          application.complianceTeamId && users.length > 0
            ? users.find(({ id }) => id === application.complianceTeamId)?.name
            : '',
        assessmentTeam:
          application.assessmentTeamId && users.length > 0
            ? users.find(({ id }) => id === application.assessmentTeamId)?.name
            : '',
        followUpTeam:
          application.followupTeamId && users.length > 0
            ? users.find(({ id }) => id === application.followupTeamId)?.name
            : '',
        fileStatus: application.fileStatus,
        fileStatusName: APPLICATION_FILE_STATUSES.find(
          ({ id }) => id == application.fileStatus
        )?.type,
        branchOffice: application.branchOfficeName,
        year: application.year,
        source:
          application.source && application.source > 0
            ? STUDENTSOURCES.find(({ id }) => id == application.source)?.type
            : '',
        origin:
          application.origin > 0
            ? LEADORIGINS.find(({ id }) => id == application.origin)?.type
            : '',
        channel:
          application.channel > 0
            ? LEADCHANNELS.find(({ id }) => id == application.channel)?.type
            : '',
        campaign: application.campaign,
        phone: application.phoneNumber,
        passport: application.passportNumber,
        applicationPartner: application.applicationPartnerId>0 ? partners.find(({ id }) => id == application.applicationPartnerId)?.name:'',
        gender:
          application.gender > 0
            ? GENDER.find(({ id }) => id == application.gender)?.type
            : '',
        dateOfBirth:
          application.dateOfBirth == null
            ? ''
            : new Date(application.dateOfBirth).toISOString().split('T')[0],
        directLink: `${window.location.origin}/students/${application.studentId}/applications/${application.id}`,
        virtualTeamId: application.virtualTeamId,
        virtualTeam:
          application.virtualTeamId && users.length > 0
            ? users.find(({ id }) => id === application.virtualTeamId)?.name
            : '',
      });
    });
    setApplications(applicationRows);
    setApplicationLoaded(true);
    setLoading(false);
    console.log('applicationRows', applicationRows);
  };
  const handleNote = (row) => {
    console.log('row', row);
    setOpenAddNote(true);
    setAppId(row.id);
    setMsgName(row.firstName + ' ' + row.lastName);
  };

  let columns = [
    {
      field: 'id',
      headerName: 'Id',
      width: 85,
      //hideable: true
      hide: true,
    },
    {
      field: 'applicationRef',
      headerName: 'App Ref.',
      width: 140,
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Student Name',
      width: 220,
      renderCell: (params) => (
        <MyTooltip title={params.row.name} color="primary">
          <Typography className="clickable text-blue-500">{params.row.name}</Typography>
        </MyTooltip>
      ),
      hide: role.isStudent,
    },
    { field: 'status',
      headerName: 'Status', 
      width: 220,
      hide: false ,
      renderCell: (params) => (
          <Typography className={params.row.statusId==4 ||  params.row.statusId==19 ||params.row.statusId==5
            || params.row.statusId==18 || params.row.statusId==11 || params.row.statusId==20 || params.row.statusId==21 
            ? 'font-semibold text-red-700' 
            : params.row.statusId== 1 ||  params.row.statusId== 2 ||  params.row.statusId== 3 ||  params.row.statusId== 13
             || params.row.statusId== 9 ||  params.row.statusId== 14 ||  params.row.statusId== 10 
            ? 'font-semibold text-teal-700'
           : params.row.statusId== 7 ||  params.row.statusId== 6 ||  params.row.statusId== 17 ||  params.row.statusId== 8 ?
            'font-semibold text-amber-600' :'font-semibold text-zinc-600' }>
            {params.row.status}
          </Typography>
      ),
    },
    {
      field: 'residence',
      headerName: 'Residence',
      width: isInternal ? 150 : 180,
      //hide: !isInternal,
    },
    {
      field: 'appliactionPriorityId',
      headerName: 'App Priority',
      width:130,
      hide: !isInternal,
      renderCell: (params) => ( console.log('params',params),
          <Rating
           max={3}
           value={params.row.applicationPriorityId}
          />
      ),
    },

    {
      field: 'citizenship',
      headerName: 'Citizenship',
      width: 150,
      hide: isInternal,
    },
    { field: 'destination', headerName: 'Destination', width: 150, hide: true },
    {
      field: 'university',
      headerName: 'Institution',
      heigth: 200,
      // hide: isInternal,
      width: role.isStudent ? 450 : 300,
      // renderCell: (params) => (
      //   <Button
      //     style={{ textTransform: 'none' }}
      //     href={`/students/${params.row.studentId}/applications/${params.id}`}
      //     target="_blank"
      //   >
      //     <MyTooltip title={params.row.university}>
      //       <Typography>{params.row.university}</Typography>
      //     </MyTooltip>
      //   </Button>
      // ),
    },
    {
      field: 'course',
      headerName: 'Course',
      width: role.isStudent ? 380 : 320,
      renderCell: (params) => (
        <MyTooltip title={params.row.course}>
          {/* <Typography>{params.row.course}</Typography> */}

          <Grid container direction="column">
            <Grid item>
              <Typography
                style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
              >
                {params.row.course}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="secondary" style={{ fontSize: '.75rem' }}>
                {params.row.courseDuration}
              </Typography>
            </Grid>
          </Grid>
        </MyTooltip>
      ),
    },

    {
      field: 'studyLevel',
      headerName: 'Level',
      width: isInternal ? 140 : 160,
    },
    {
      field: 'year',
      headerName: 'Year',
      width: isInternal ? 100 : 120,
    },

    {
      field: 'intake',
      headerName: 'Intake',
      width: isInternal ? 120 : 250,
    },
    {
      field: 'note',
      headerName: 'Note',
      width: 90,
      hide: !isInternal,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <IconButton
          disabled={!isInternal}
          onClick={() => handleNote(params.row)}
          size="large"
        >
          <Description />
        </IconButton>
      ),
    },
    {
      field: 'eventName',
      headerName: 'Event Name',
      width: 200,
      hide: true,
    },
    {
      field: 'branchOffice',
      headerName: 'Branch',
      width: 100,
      hide: !isInternal,
    },
    {
      field: 'agentCompanyName',
      headerName: 'Agent',
      hide: !isInternal,
      width: 250,
      renderCell: (params) => (
        <MyTooltip title={params.row.agentCompanyName}>
          <Typography style={{ whiteSpace: 'normal' }}>
            {params.row.agentCompanyName}
          </Typography>
        </MyTooltip>
      ),
    },
    {
      field: 'Referral',
      headerName: 'Referral',
      hide: false,
      width: 250,
      renderCell: (params) => (
        <MyTooltip title={params.row.referralName}>
          <Typography style={{ whiteSpace: 'normal' }}>
            {params.row.referralName}
          </Typography>
        </MyTooltip>
      ),
    },
    // { field: 'studentId', headerName: 'Student Id', width: 160 },
    {
      field: 'appliedOn',
      headerName: 'Added',
      width: 85,
      hide:
        role.isStudent ||
        role.isAdmin ||
        role.isSeniorAdmin ||
        role.isSuperAdmin ||
        role.isAgent,
    },
    { field: 'appliedOnActual', headerName: 'Applied', width: 85, hide: true },
    { field: 'lastActionedOn', headerName: 'Actioned', width: 85, hide: true },
    {
      field: 'admin',
      hide: false,
      headerName: 'Team Leader',
      width: 170,
      renderCell: (params) => (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <MyTooltip icon={<EmailIcon />} title="Message Admin">
              <IconButton
                color="primary"
                onClick={() =>
                  addMessage(params.row.adminAppUserId, params.row.admin)
                }
                size="large"
              >
                <EmailIcon />
              </IconButton>
            </MyTooltip>
          </Grid>
          <Grid item xs={9} container alignItems="center">
            <MyTooltip title={params.row.admin}>
              <Typography>{params.row.admin}</Typography>
            </MyTooltip>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'appTeam',
      headerName: 'App Team',
      width: 150,
      hide:
        role.isStudent ||
        role.isAdmin ||
        role.isSeniorAdmin ||
        role.isSuperAdmin ||
        role.isAgent,
      renderCell: (params) => (
        <Grid container spacing={2}>
          {params.row.appTeam && (
            <Grid item xs={3}>
              <MyTooltip icon={<EmailIcon />} title="Message Application Team">
                <IconButton
                  color="primary"
                  onClick={() =>
                    addMessage(
                      params.row.admissionTeamAppUserId,
                      params.row.appTeam
                    )
                  }
                  size="large"
                >
                  <EmailIcon />
                </IconButton>
              </MyTooltip>
            </Grid>
          )}
          <Grid item xs={9} container alignItems="center">
            <MyTooltip title={params.row.appTeam}>
              <Typography>{params.row.appTeam}</Typography>
            </MyTooltip>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'assessmentTeam',
      headerName: 'Assessment',
      // hide: !(role.isApplicationTeamLeader || role.isSuperAdmin),
      hide: !isInternal,
      width: 140,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.assessmentTeam}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'followUpTeam',
      headerName: 'Follow Up',
      hide: !isInternal,
      width: 140,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.followUpTeam}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'conversionTeam',
      headerName: 'Conversion',
      hide: true,
      width: 140,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.conversionTeam}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'complianceTeam',
      headerName: 'Compliance',
      hide: true,
      width: 140,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.complianceTeam}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'complianceStatus',
      headerName: 'Compliance Status',
      hide: false,
      width: 200,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.complianceStatusName}</Typography>
          </Grid>
        </Grid>
      ),
    },

    {
      field: 'virtualTeam',
      headerName: 'Uni. Representative',
      hide: true,
      width: 140,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.virtualTeam}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'applicationPartner',
      headerName: 'Partner',
      hide: true,
      width: 160,
    },
    {
      field: 'source',
      headerName: 'Lead Source',
      hide: true,
    },
    {
      field: 'origin',
      headerName: 'Lead Origin',
      hide: true,
    },
    {
      field: 'channel',
      headerName: 'Lead Channel',
      hide: true,
    },
    {
      field: 'campaign',
      headerName: 'Campaign',
      hide: true,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      hide: true,
    },
    {
      field: 'dateOfBirth',
      headerName: 'Date of Birth',
      hide: true,
    },
    {
      field: 'passport',
      headerName: 'Passport',
      hide: true,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      hide: true,
    },
    {
      field: 'directLink',
      headerName: 'Direct Link',
      hide: true,
    },
    {
      field: 'bdm',
      headerName: 'Business Dev Manager',
      hide: false,
      width: 180,
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 90,
      headerAlign: 'center',
      hide: !isInternal,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <IconButton
          disabled={!isInternal}
          onClick={() => showEditOptions(params.row)}
          size="large"
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];
  const actionSelectedApp = (record) => {
    const assignDto = {
      applications: [appToEdit.id],
      conversionTeamId: record.conversionTeamId,
      complianceTeamId: record.complianceTeamId,
      virtualTeamId: record.virtualTeamId,
      status: parseInt(record.fileStatus),
      complianceStatus: record.complianceStatus,
      complianceStatusChanged: record.complianceStatusChanged,
    };
    Agent.Applications.assign(assignDto)
      .then(() => {
        //lets update the grid now
        let temp = [];
        applications.map((item) => {
          if (item.id == appToEdit.id) {
            if (item.fileStatus != record.fileStatus) {
              item.fileStatus = parseInt(record.fileStatus);
              item.fileStatusName = APPLICATION_FILE_STATUSES.find(
                ({ id }) => id == record.fileStatus
              )?.type;
            }
            if (item.conversionTeamId != record.conversionTeamId) {
              item.conversionTeamId = record.conversionTeamId;
              item.conversionTeam = record.conversion;
            }
            if (item.complianceTeamId != record.complianceTeamId) {
              item.complianceTeamId = record.complianceTeamId;
              item.complianceTeam = record.compliance;
            }
            if (item.virtualTeamId != record.virtualTeamId) {
              item.virtualTeamId = record.virtualTeamId;
              item.virtualTeam = record.virtualTeam;
            }
            if (item.complianceStatus != record.complianceStatus) {
              item.complianceStatus = record.complianceStatus;
              item.complianceStatusName = record.complianceStatusName;
            }
          }
          temp.push(item);
        });
        setApplications(temp);
        setReadyEdit(false);
        setAppToEdit(null);
      })
      .catch((error) => {
        console.log(error);
        setAppToEdit(null);
        setReadyEdit(false);
        debugger;
      });
  };

  const actionSelectedUser = (admin) => {
    const assignDto = {
      applications: selectionModel,
      adminAppUserDetailsId: admin.id,
    };
    Agent.Applications.assign(assignDto)
      .then(() => {
        let temp = [];

        applications.map((item) => {
          console.log(selectionModel);
          if (selectionModel.includes(item.id)) {
            temp.push((item = { ...item, appTeam: admin.name }));
          } else {
            temp.push(item);
          }
        });
        setApplications(temp);
        setReady(false);
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  };

  const showAdminAssignment = () => {
    if (users.length == 0) {
      loadUsers()
        .then(() => {
          setReady(true);
        })
        .catch((error) => {
          console.log(error);
          debugger;
        });
    } else {
      setReady(true);
    }
  };

  return !applicationLoaded && !role.isStudent ? (
    <div className="flex flex-col gap-12 pt-3">
      <LoadingView />
      <LoadingView />
    </div>
  ) : applicationLoaded && !role.isStudent ? (
    <>
      <Grid
        container
        direction="column"
        spacing={1}
        // style={{ backgroundColor: 'white' }}
      >
        {!role.isStudent && (
          <>
            <Grid item container spacing={1}>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size="small"
                  label="Application Id"
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size="small"
                  label="Student Id"
                  value={studentId}
                  onChange={(e) => setStudentId(e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size="small"
                  label="Student Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    open={openResCountry}
                    onOpen={() => {
                      setOpenResCountry(true);
                    }}
                    onClose={() => {
                      setOpenResCountry(false);
                    }}
                    value={countryOfResidance}
                    onChange={(_, newVal) => {
                      setCountryOfResidance(newVal);
                    }}
                    getOptionLabel={(option) => `${option.name}`}
                    options={tempCountry.filter((item)=>item.id!=0)}
                    renderInput={(params) => (
                      <TextField
                        inputRef={appTeamRef}
                        {...params}
                        label="Country Of Residence"
                        placeholder="Country Of Residence"
                       
                      />
                    )}
                  />
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <InputLabel>Intake</InputLabel>
                  <Select
                    id="demo-controlled-open-select"
                    value={intake}
                    onChange={(e) => {
                      setIntake(e.target.value);
                    }}
                    size="small"
                    label="Intake"
                  >
                    <MenuItem value={0}>All Intakes</MenuItem>
                    {INTAKES.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {(role.isApplicationTeamLeader || role.isSuperAdmin) && (
                <Grid item container justifyContent="flex-end" xs={2}>
                  <Grid item>
                    <Button
                      onClick={showAdminAssignment}
                      variant="outlined"
                      disabled={selectionModel.length === 0}
                    >
                      Assign App Team
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item container spacing={1}>
              {!role.isAgent && (
                <Grid item xs={2}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    // sx={{ width: 500 }}
                    open={openAdmin}
                    onOpen={() => {
                      setOpenAdmin(true);
                    }}
                    onClose={() => {
                      setOpenAdmin(false);
                    }}
                    value={cosmosAdmin}
                    onChange={(_, newVal) => {
                      setCosmosAdmin(newVal);
                    }}
                    getOptionLabel={(option) => `${option.name}`}
                    options={cosmosAdminUsers}
                    loading={adminLoading}
                    renderInput={(params) => (
                      <TextField
                        inputRef={adminRef}
                        {...params}
                        label="Team Leader"
                        placeholder="Team Leader"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {adminLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              {!(role.isAgent || role.isApplicationTeam) && (
                <Grid item xs={2}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    // sx={{ width: 500 }}
                    open={openAppTeam}
                    onOpen={() => {
                      setOpenAppTeam(true);
                    }}
                    onClose={() => {
                      setOpenAppTeam(false);
                    }}
                    value={cosmosAppTeam}
                    onChange={(_, newVal) => {
                      setCosmosAppTeam(newVal);
                    }}
                    getOptionLabel={(option) => `${option.name}`}
                    options={cosmosAppTeamUsers}
                    loading={appTeamLoading}
                    renderInput={(params) => (
                      <TextField
                        inputRef={appTeamRef}
                        {...params}
                        label="App Team"
                        placeholder="App Team"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {appTeamLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={2}>
                <FormControl>
                  <InputLabel>Status</InputLabel>
                  <Select
                    size="small"
                    native
                    label="Status"
                    placeholder="Status"
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                  >
                    <option value={''}></option>
                    {role.isApplicationTeam || role.isApplicationTeamLeader
                      ? APPLICATION_STATUSES.filter(
                          (item) => item.displayInApp
                        ).map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.type}
                          </option>
                        ))
                      : APPLICATION_STATUSES.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.type}
                          </option>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size="small"
                  label="Institution"
                  value={institution}
                  onChange={(e) => setInstitution(e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  fullWidth
                  size="small"
                  value={destination}
                  onChange={(_, newVal) => {
                    setDestination(newVal);
                  }}
                  getOptionLabel={(option) => `${option.name}`}
                  options={Countries}
                  loading={appTeamLoading}
                  renderInput={(params) => (
                    <TextField
                      // inputRef={citizenshipRef}
                      {...params}
                      label="Destination"
                      placeholder="Destination"
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </Grid>
              {isInternal && (
                <Grid item xs={2}>
                  <Select
                    fullWidth
                    size="small"
                    native
                    label="Branch Office"
                    value={branchOfficeId}
                    onChange={(e) => {
                      setBranchOfficeId(e.target.value);
                    }}
                  >
                    <option value={0}>All Branches</option>
                    {offices.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </Grid>
              )}
              {isInternal && (
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel>Origin</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Origin"
                      value={leadOrigin}
                      onChange={(e) => {
                        setLeadOrigin(e.target.value);
                      }}
                    >
                      <option value={0}> -- select origin --</option>
                      {LEADORIGINS.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {isInternal && (
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel>Source</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Source"
                      value={leadSource}
                      onChange={(e) => {
                        setLeadSource(e.target.value);
                      }}
                    >
                      <option value={0}> -- select source --</option>
                      {STUDENTSOURCES.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {!isInternal && <div className="w-48"></div>}
              {isInternal && (
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel> Channel</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Channel"
                      value={leadChannel}
                      onChange={(e) => {
                        setLeadChannel(e.target.value);
                      }}
                    >
                      <option value={0}>-- select channel --</option>
                      {LEADCHANNELS.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {isInternal && (
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel> Assessment</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Assessment"
                      value={assessmentTeam}
                      onChange={(e) => {
                        setAssessmentTeam(e.target.value);
                      }}
                    >
                      <option value={0}>Assessment</option>
                      {filteredFollowAndAssessmentTeams.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {isInternal && (
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel> Follow-up Team</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Follow-up Team"
                      value={followupTeamId}
                      onChange={(e) => {
                        setFollowUpTeam(e.target.value);
                      }}
                    >
                      <option value={0}>Follow-up Team</option>
                      {filteredFollowAndAssessmentTeams.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {isInternal && (
                <Grid item xs={2}>
                  <Select
                    fullWidth
                    size="small"
                    native
                    label="Student Type"
                    value={studentType}
                    onChange={(e) => {
                      setStudentType(e.target.value);
                    }}
                  >
                    <option value={0}>All Student Type</option>
                    <option value={1}>Agent Students</option>
                    <option value={2}>Direct Students</option>
                    <option value={3}>Referral Students</option>
                  </Select>
                </Grid>
              )}
            </Grid>
            <div className="flex flex-row justify-between items-center gap-2 mt-3 ml-2">
            {isInternal && (
                <div className="w-56">
                  <FormControl fullWidth>
                    <InputLabel>Application Priority</InputLabel>
                    <Select
                      size="small"
                      native
                      label="Application Priority"
                      placeholder="Application Priority"
                      value={appPriorityId}
                      onChange={(e) => {
                        setAppPriorityId(e.target.value);
                      }}
                    >
                      <option value={0}>All Priority</option>
                      <option value={1}>Low</option>
                      <option value={2}>Medium</option>
                      <option value={3}>High</option>
                    </Select>
                  </FormControl>
                </div>
              )}
              {isInternal && (
                <div className="w-56">
                  <FormControl fullWidth>
                    <InputLabel>Date Type</InputLabel>
                    <Select
                      size="small"
                      native
                      label="Date Type"
                      placeholder="Date Type"
                      value={dateType}
                      onChange={(e) => {
                        setDateType(e.target.value);
                      }}
                    >
                      <option value={1}>Application Added</option>
                      <option value={2}>Course Start</option>
                      <option value={3}>Status Changed</option>
                    </Select>
                  </FormControl>
                </div>
              )}

              {isInternal && (
                <Grid item xs={2}>
                <Autocomplete
                  fullWidth
                  size="small"
                  open={openAgent}
                  onOpen={() => {
                    setOpenAgent(true);
                  }}
                  onClose={() => {
                    setOpenAgent(false);
                  }}
                  value={agentFilterId}
                  onChange={(_, newVal) => 
                    setAgentFilterId(newVal)
                  }
                  getOptionLabel={(option) => `${option.companyName}`}
                  options={AgentList}
                  //loading={adminLoading}
                  renderInput={(params) => (
                    <TextField
                      inputRef={agentRef}
                      {...params}
                      label="Agent"
                      placeholder="Agent"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {agentLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              )}

              {!(role.isAgent || role.isApplicationTeam) && (
                <Grid item xs={2}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    // sx={{ width: 500 }}
                    open={openBdmTeam}
                    onOpen={() => {
                      setOpenBdmTeam(true);
                    }}
                    onClose={() => {
                      setOpenBdmTeam(false);
                    }}
                    value={cosmosBdmTeam}
                    onChange={(_, newVal) => {
                      setCosmosBdmTeam(newVal);
                    }}
                    getOptionLabel={(option) => `${option.name}`}
                    options={cosmosBdmUsers}
                    loading={appBdmLoading}
                    renderInput={(params) => (
                      <TextField
                        inputRef={bdmRef}
                        {...params}
                        label="BDM"
                        placeholder="BDM"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {appBdmLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              )}

              {isInternal && (
                <Grid item xs={2}>
                  <FormControl>
                    <InputLabel>Events</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Event Name"
                      value={eventId}
                      onChange={(e) => {
                        setEventId(e.target.value);
                      }}
                    >
                      <option value={0}>All Event</option>
                      {events.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.eventName}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {isInternal && (
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel>Compliance Status</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Compliance Status"
                      value={complianceStatus}
                      onChange={(e) => {
                        setComplianceStatus(e.target.value);
                      }}
                    >
                      {/* <option value={0}> Unknown</option> */}
                      {COMPLIANCE_STATUS.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {isInternal && (
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <InputLabel>Referral</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Referral"
                      value={referralAssignId}
                      onChange={(e) => {
                        setReferralAssignId(e.target.value);
                      }}
                    >
                      <option value={0}>All Referrals</option>
                      {referralList?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.firstName + ' ' + item.lastName}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              
              
              {!isInternal && (
                <> <div className='w-64'></div>
                 <div>
                  <DatePicker
                    // popperClassName={classes.reactDatepickerPopper}
                    autoComplete="off"
                    locale="enGB"
                    dateFormat="dd-MM-yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    error={true}
                    selected={(start && new Date(start)) || null}
                    onChange={(newVal) => {
                      if (newVal) {
                        newVal = addHours(startOfDay(newVal), 12);
                      }
                      setStart(newVal);
                    }}
                    customInput={
                      <TextField
                        label="Start Date"
                        fullWidth
                        // onChange={onChange}
                        value={start}
                        variant="outlined"
                        size="small"
                      />
                    }
                  />
                  </div>
                  <div>
                  <DatePicker
                    // popperClassName={classes.reactDatepickerPopper}
                    autoComplete="off"
                    locale="enGB"
                    dateFormat="dd-MM-yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    error={true}
                    selected={(end && new Date(end)) || null}
                    onChange={(newVal) => {
                      if (newVal) {
                        newVal = addHours(startOfDay(newVal), 12);
                      }
                      setEnd(newVal);
                    }}
                    customInput={
                      <TextField
                        label="End Date"
                        fullWidth
                        // onChange={onChange}
                        value={start}
                        variant="outlined"
                        size="small"
                      />
                    }
                  />
                  </div>
                  <div>
                    <Button
                      onClick={applyFilter}
                      variant="contained"
                      className="bg-sky-400 hover:bg-sky-500 w-48"
                    >
                      Apply Filters
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={resetFilter}
                      variant="outlined"
                      className="w-48"
                    >
                      Reset Filters
                    </Button>
                  </div>
                </>
              )}
              
              {!isInternal && <div className="w-64"></div>}
            </div>
            {isInternal && (
              <>
                <Grid
                  container
                  justifyContent={'flex-end'}
                  spacing={1}
                  className="pt-2 pb-2 pl-2"
                >
                   {isInternal && (
                <Grid item xs={2}>
                <DatePicker
                  // popperClassName={classes.reactDatepickerPopper}
                  autoComplete="off"
                  locale="enGB"
                  dateFormat="dd-MM-yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  error={true}
                  selected={(start && new Date(start)) || null}
                  onChange={(newVal) => {
                    if (newVal) {
                      newVal = addHours(startOfDay(newVal), 12);
                    }
                    setStart(newVal);
                  }}
                  customInput={
                    <TextField
                      label="Start Date"
                      fullWidth
                      // onChange={onChange}
                      value={start}
                      variant="outlined"
                      size="small"
                    />
                  }
                />
                 </Grid>
                )}
              {isInternal && (
                <Grid item xs={2}>
                <DatePicker
                  // popperClassName={classes.reactDatepickerPopper}
                  autoComplete="off"
                  locale="enGB"
                  dateFormat="dd-MM-yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  error={true}
                  selected={(end && new Date(end)) || null}
                  onChange={(newVal) => {
                    if (newVal) {
                      newVal = addHours(startOfDay(newVal), 12);
                    }
                    setEnd(newVal);
                  }}
                  customInput={
                    <TextField
                      label="End Date"
                      fullWidth
                      // onChange={onChange}
                      value={start}
                      variant="outlined"
                      size="small"
                    />
                  }
                />
               </Grid>
                )}
                   {isInternal && (
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel> Partner</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Partner"
                      value={applicationPartnerId}
                      onChange={(e) => {
                        setApplicationPartnerId(e.target.value);
                      }}
                    >
                       <option value={0}>All Partner</option> 
                       {partners.filter((item) => !item.isInactive)
                            .map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                    </Select>
                  </FormControl>
                </Grid>
                )}
               <Grid item xs={2}>
                <TextField
                  fullWidth
                  size="small"
                  label="Application Ref"
                  value={applicationRef}
                  onChange={(e) => setApplicationRef(e.target.value)}
                />
               </Grid>
               <Grid item xs={2}>
                <FormControl fullWidth>
                  <Autocomplete
                    value={
                      intakeYearList.find(
                        (intake) => intake.year == parseInt(intakeYear)
                      ) || null
                    }
                    onChange={handleIntakeYearChange}
                    options={intakeYearList}
                    getOptionLabel={(intakeYearList) => intakeYearList.year}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Intake Year"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
             </Grid>
                  {isInternal && (
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel> Study Level</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Study Level"
                      value={studyLevelId}
                      onChange={(e) => {
                        setStudyLevelId(e.target.value);
                      }}
                    >
                       <option value={0}>All Level</option> 
                      {STUDY_LEVELS.filter((val)=>val.id!=0).map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                )}
             </Grid>
                 <Grid
                  container
                  justifyContent={'flex-end'}
                  spacing={1}
                  className="pt-2 pb-2"
                >
                  <Grid item xs={2}>
                    <Button
                      fullWidth
                      onClick={getData}
                      variant="contained"
                      className="bg-sky-400 hover:bg-sky-500"
                    >
                      Apply Filters
                    </Button>
                  </Grid>
                  <Grid item xs={2}>
                    <Button fullWidth onClick={resetFilter} variant="outlined">
                      Reset Filters
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}

        <Grid item style={{ height: 710, width: '100%' }}>
          <DataGridPro
            applicationHeader
            //={classes.root}
            rowHeight={90}
            disableSelectionOnClick
            rows={applications}
            columns={columns}
            loading={loading}
            pagination
            totalPageSize={totalPages}
            paginationMode="server"
            pageSize={pageSize}
            pageSizeOptions={[800]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowCount={totalrows}
            onPageChange={(newPage) => setPageNumber(newPage + 1)}
            page={pageNumber - 1}
            components={{
              Toolbar: () =>
                CustomToolbar(permissions.CanDownloadData, isInternal),
              Pagination: GridPagination,
            }}
            density="compact"
            checkboxSelection={
              !(
                role.isStudent ||
                role.isAdmin ||
                role.isSeniorAdmin ||
                role.isSuperAdmin ||
                role.isAgent
              )
            }
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            disable
            disableColumnFilter={true}
            disableColumnSelector={role.isStudent}
            disableDensitySelector={role.isStudent}
            onCellClick={(params, event) => {
              event.defaultMuiPrevented = true;
              if (params.colDef.field === 'name') {
                window.open(
                  `/students/${params.row.studentId}/applications/${params.id}`,
                  '_blank'
                );
              }
            }}
          />
        </Grid>

        {readyEdit && (
          <ApplicationAssignment
            application={appToEdit}
            users={users.filter((item) => item.isDisabled == false)}
            cancel={setReadyEdit}
            action={actionSelectedApp}
            role={role}
          />
        )}
        {ready && (
          <Search
            users={users.filter(
              (user) => user.userType == 7 || user.userType == 8
            )}
            cancel={setReady}
            action={actionSelectedUser}
            title="Application Team Selection"
          />
        )}
        {openAddMessage && (
          <AddMessage
            open={true}
            cancel={setOpenAddMessage}
            agentId={0} // message being sent from admin to an agent
            toId={to.id}
            toName={to.name}
          />
        )}
        {openAddNote && (
          <ApplicationNotes
            // records={notes}
            //setRecords={setNotes}
            open={openAddNote}
            cancel={setOpenAddNote}
            appId={appId}
            toName={userName}
          />
        )}
      </Grid>
    </>
  ) : (
    <>
      {role.isStudent && (
        <div className="w-full p-6 flex justify-center">
          <StudentAppList />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    users: state.users,
    role: state.auth.role,
    permissions: state.auth.permissions,
    agentId: ownProps && ownProps.agentId ? ownProps.agentId : 0,
    isInternal: state.auth.isInternal,
    userName: state.auth.name,
    partners: state.management.partners,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadPartners: () => dispatch(actions.loadPartners()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Applications);
