/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';

import * as actions from '../../../store/actions';
import {
  MUI_LICENSE_KEY,
  STUDENTSOURCES,
  LEADORIGINS,
  LEADCHANNELS,
  STUDENTSTATUS,
  EVENT_CATEGORIES,
  EVENT_STATUSES,
} from '../../../Util/Constants.js';
import {
  LicenseInfo,
  DataGridPro,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { connect } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // the locale you want
registerLocale('en-GB', enGB); // register it with the name you want
import 'react-datepicker/dist/react-datepicker.css';
import { startOfDay, addHours, addMonths } from 'date-fns';
import { Countries } from '../../../Util/Util';
import MyTooltip from '../../Common/Tooltip/MyTooltip';
import Agent from '../../../api/agent';
import LoadingView from '../../Common/Loading/LoadingView';
import EditIcon from '@mui/icons-material/Edit';
import { LeadEditEvents } from './LeadEditEvents';
import moment from 'moment/moment';

//import {StudentLeadAssignment } from '../../Leads/StudentLead/StudentLeadAssign';

function CustomToolbar(show, isInternal) {
  return (
    <GridToolbarContainer>
      {isInternal && <GridToolbarColumnsButton />}
      {/* {isInternal && <GridToolbarFilterButton />} */}
      {show && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}

const LeadEvents = ({
  users,
  role,
  permissions,
  loadUsers,
  agents,
  isInternal,
  loadAgents,
}) => {
  const navigate = useNavigate();
  const [eventsLeadLoaded, setEventsLeadLoaded] = useState(false);
  const [page, setPage] = React.useState(1);
  const pageSize = 50;
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [eventName, setEventName] = useState('');
  const [eventRow, setEventRow] = useState();
  const [eventDate, setEventDate] = useState(null);
  const [start, setStart] = useState(addMonths(new Date(), -1));
  const [end, setEnd] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [countryId, setCountryId] = useState(null);
  const [eventCategoryId, setEventCategoryId] = useState(0);
  const [eventType, setEventType] = useState(0);
  const [offices, setOffices] = useState([]);
  const [bulkUpdate, setBulkUpdate] = useState();
  const [adminAssignMode, setAdminAssignMode] = useState(false);
  const [agentAssignMode, setAgentAssignMode] = useState(false);
  const [branchOfficeId, setBranchOfficeId] = useState(0);
  const countryRef = useRef();
  const [ready, setReady] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setOffices);
  }, []);
  //formatted using momentjs
  const formattedDate = (date) => {
    ``;
    return moment(date).format('YYYY-MM-DD');
  };
  const handleAddEvent = () => {
    navigate('/addeventform');
  };
  const [selectionModel, setSelectionModel] = useState([]);
  const [event, setEvent] = useState();

  const actionSelectedUser = (record) => {
    const assignDto = {
      id: record.id,
      eventName: record.eventName,
      countryId: record.countryId ? parseInt(record.countryId) : null,
      country:
        parseInt(record.countryId) > 0
          ? Countries.find(({ id }) => id == parseInt(record.countryId)).name
          : '',
      eventLocation: record.eventLocation,
      eventDate: record.eventDate,
      eventBudget: parseFloat(record.eventBudget),
      formUrl: record.formUrl,
      isInactive: record.isInactive,
      eventCategory: parseInt(record.eventCategoryId),
      branchOfficeId: record.branchOfficeId
        ? parseInt(record.branchOfficeId)
        : null,
      leadSource: record.leadSource,
      source:
        record.leadSource > 0
          ? STUDENTSOURCES.find(({ id }) => id == record.leadSource)?.type
          : '',
      leadOrigin: record.leadOrigin,
      origin:
        record.leadOrigin > 0
          ? LEADORIGINS.find(({ id }) => id == record.leadOrigin)?.type
          : '',
      leadChannel: record.leadChannel,
      channel:
        record.leadChannel > 0
          ? LEADCHANNELS.find(({ id }) => id == record.leadChannel)?.type
          : '',
      countryIdChanged: record.countryIdChanged,
      eventBudgetChanged: record.eventBudgetChanged,
      eventDateChanged: record.eventDateChanged,
      eventLocationChanged: record.eventLocationChanged,
      branchOfficeChanged: record.branchOfficeChanged,
      formUrlChanged: record.formUrlChanged,
      leadSourceChanged: record.sourceChanged,
      leadOriginChanged: record.originChanged,
      leadChannelChanged: record.channelChanged,
      eventNameChanged: record.eventNameChanged,
      eventCategoryChanged: record.eventCategoryChanged,
      isInactiveChanged: record.isInactiveChanged,
    };

    console.log('Assign DTO stund:', assignDto, record); // Console log the assignment data before making the API call

    Agent.Events.assign({ ...record, ...assignDto })
      .then(() => {
        if (bulkUpdate) {
          setReady(false);
          return;
        }
        debugger;
        //lets update the grid now
        let temp = [];
        events.map((item) => {
          if (item.id == event.id) {
            item.id = record.id;
            item.countryId = record.countryId;
            item.eventName = record.eventName;
            item.eventDate = record.eventDate;
            // if(item.countryId && record.countryId>0 ?
            //   Countries.find(({ id }) => id == record.id).name
            // : '')
            // if (item.countryId != record.countryId) {
            //   item.countryId = record.countryId;
            //   item.country = record.country;
            // }
            if (item.eventLocation != record.eventLocation) {
              item.eventLocation = record.eventLocation;
            }
            if (item.branchOfficeId != record.branchOfficeId) {
              item.branchOfficeId = record.branchOfficeId;
              item.branchOfficeName = record.branchOfficeName;
            }
            if (item.eventBudget != record.eventBudget) {
              item.eventBudget = record.eventBudget;
            }
            if (item.formUrl != record.formUrl) {
              item.formUrl = record.formUrl;
            }
            item.leadChannel = parseInt(record.leadChannel);
            item.leadOrigin = parseInt(record.leadOrigin);
            item.leadSource = parseInt(record.leadSource);
            (item.source =
              record.leadSource > 0
                ? STUDENTSOURCES.find(({ id }) => id == record.leadSource)?.type
                : ''),
              (item.origin =
                record.leadOrigin > 0
                  ? LEADORIGINS.find(({ id }) => id == record.leadOrigin)?.type
                  : ''),
              (item.channel =
                record.leadChannel > 0
                  ? LEADCHANNELS.find(({ id }) => id == record.leadChannel)?.type
                  : '');
          }
          temp.push(item);
          setSuccessMessage('Update Successfully');
          setSuccessOpen(true);
          getData(true);
          console.log('item', item);
        });
        setEvents(temp);
        setReady(false);
        setEvent(null);
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage('Error occurred.');
        setEvent(null);
        setAdminAssignMode(false);
        setAgentAssignMode(false);
        setReady(false);
        debugger;
      });
  };

  const getParams = (filter) => {
    const params = new URLSearchParams();
    params.append('pageNumber', page.toString());
    params.append('pageSize', pageSize.toString());
    params.append('id', filter ? id.trim() : '');
    params.append('name', filter ? eventName.trim() : '');
    if (countryId && filter) {
      params.append('countryId', countryId.id);
    }
    if (filter) {
      params.append('eventCategoryId', eventCategoryId);
    }
    if (eventDate && filter) {
      params.append('eventDate', new Date(eventDate).toDateString());
    }
    if (filter) {
      if (start) {
        params.append('startDate', new Date(start).toDateString());
      }
    } else {
      params.append('startDate', addMonths(new Date(), -1).toDateString());
    }
    if (filter) {
      if (end) {
        params.append('endDate', new Date(end).toDateString());
      }
    } else {
      params.append('endDate', new Date().toDateString());
    }
    if (filter) {
      params.append('branchOfficeId', branchOfficeId);
    }
    if (filter) {
      params.append('eventCategory', eventCategoryId);
    }
    if (filter) {
      params.append('EventType', eventType);
    }

    return params;
  };

  const getRows = (rows) => {
    let eventRows = [];
    rows.map((event) => {
      eventRows.push({
        id: event.id,
        eventName: event.eventName,
        country:
          parseInt(event.countryId) > 0
            ? Countries.find(({ id }) => id === parseInt(event.countryId)).name
            : '',
        countryId:
          event.countryId && event.countryId > 0
            ? Countries.find(({ id }) => id == event.countryId).id
            : '',
        eventLocation: event.eventLocation,
        eventDate:
          event.eventDate == null ? '' : formattedDate(event.eventDate),
        branchOfficeId: event.branchOfficeId,
        branch: event.branchOfficeName,
        eventStatus: event.isInactive
          ? EVENT_STATUSES.find(({ id }) => id == 2)?.type
          : EVENT_STATUSES.find(({ id }) => id == 1)?.type,
        eventStatusId: event.isInactive ? 2 : 1,
        eventCategory: event.eventCategory
          ? EVENT_CATEGORIES.find(({ id }) => id == event.eventCategory)?.type
          : '',
        eventCategoryId: event.eventCategory,
        leadSource: event.leadSource,
        source:
          event.leadSource > 0
            ? STUDENTSOURCES.find(({ id }) => id == event.leadSource)?.type
            : '',
        leadOrigin: event.leadOrigin,
        origin:
          event.leadOrigin > 0
            ? LEADORIGINS.find(({ id }) => id == event.leadOrigin)?.type
            : '',
        leadChannel: event.leadChannel,
        channel:
          event.leadChannel > 0
            ? LEADCHANNELS.find(({ id }) => id == event.leadChannel)?.type
            : '',
        eventBudget: parseFloat(event.eventBudget),
        numberOfLeads: event.numberOfLeads,
        formUrl: event.formUrl,
        createdOn:
          event.createdOn == null
            ? ''
            : new Date(event.createdOn).toISOString().split('T')[0],
        attendanceUrl: 'https://portal.tclglobal.co.uk/event/attendanceform',
      });
    });
    setEvents(eventRows);
    setEventsLeadLoaded(true);
    setLoading(false);
  };

  useEffect(() => {
    if (isInternal) {
      if (users.length == 0) {
        loadUsers()
          .then(() => {
            //  getData();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        getData();
      }
    } else {
      getData();
    }
  }, [users]);

  let eventColumns = [
    {
      field: 'id',
      headerName: 'id',
      width: 180,
      hide: true,
    },
    {
      field: 'eventName',
      headerName: 'Event Name',
      width: 250,
      renderCell: (params) => (
        <MyTooltip title={params.row.eventName} color="primary">
          <Typography className="clickable">{params.row.eventName}</Typography>
        </MyTooltip>
      ),
    },
    {
      field: 'country',
      headerName: 'Country',
      hide: false,
      width: 150,
    },
    {
      field: 'eventLocation',
      headerName: 'Event Location',
      width: 150,
      hide: false,
    },
    {
      field: 'numberOfLeads',
      headerName: 'Lead Count',
      width: 100,
      hide: false,
    },
    {
      field: 'eventDate',
      headerName: 'Event Date',
      width: 100,
      hide: false,
    },
    {
      field: 'branch',
      headerName: 'Branch Name',
      hide: false,
      width: 170,
    },
    {
      field: 'origin',
      headerName: 'Lead Origin',
      hide: false,
      width: 140,
    },
    {
      field: 'source',
      headerName: 'Lead Source',
      hide: false,
      width: 140,
    },
    {
      field: 'channel',
      headerName: 'Lead Channel',
      hide: true,
      width: 140,
    },
    {
      field: 'eventBudget',
      headerName: 'Event Budget',
      hide: true,
      width: 100,
    },
    // {
    //   field: 'formUrl',
    //   headerName: 'Form Url',
    //   hide: false,
    //   width: 140,
    // },
    {
      field: 'eventStatus',
      headerName: 'Event Type',
      width: 110,
      hide: false,
    },
    {
      field: 'eventCategory',
      headerName: 'Event Category',
      hide: false,
      width: 120,
    },
    {
      field: 'createdOn',
      headerName: 'Created Date',
      width: 110,
      hide: true,
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 70,
      hide: !isInternal || bulkUpdate,
      renderCell: (params) => (
        <IconButton
          disabled={!isInternal}
          onClick={() => showEditOptions(params.row)}
          size="large"
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];
  const getData = (filter = false) => {
    debugger;
    setLoading(true);
    Agent.Events.list(getParams(filter))
      .then((data) => {
        console.log('Events data', data);
        getRows(data);
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  };

  const resetFilter = () => {
    setCountryId(null);
    setId('');
    setName('');
    setEventName('');
    setBranchOfficeId(0);
    setEventCategoryId(0);
    setEventDate(null);
    setStart(addMonths(new Date(), -1));
    setEnd(new Date());
    getData(false);
    setEventType(0);
    countryRef.current.value = '';
  };

  const showEditOptions = (row) => {
    setEvent(row);
    console.log('Row', row);
    if (users.length == 0) {
      loadUsers()
        .then(() => {
          if (agents.length == 0) {
            loadAgents()
              .then(() => {
                setReady(true);
              })
              .catch((error) => {
                console.log(error);
                debugger;
              });
          }
        })
        .catch((error) => {
          console.log(error);
          debugger;
        });
    } else {
      if (agents.length == 0) {
        loadAgents()
          .then(() => {
            setReady(true);
          })
          .catch((error) => {
            console.log(error);
            debugger;
          });
      } else {
        setReady(true);
      }
    }
  };
  const successDialogStyles = {
    backgroundColor: 'bg-green-400',
  };
  const errorDialogStyles = {
    backgroundColor: 'bg-red-400',
  };
  return eventsLeadLoaded ? (
    <>
      <Dialog
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
        aria-labelledby="success-dialog-title"
        PaperProps={{
          className: successDialogStyles.backgroundColor, // Apply successDialogStyles here
        }}
      >
        <DialogTitle id="success-dialog-title">Success</DialogTitle>
        <DialogContent>
          <DialogContentText>{successMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuccessOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!errorMessage}
        onClose={() => setErrorMessage('')}
        aria-labelledby="error-dialog-title"
        PaperProps={{
          className: errorDialogStyles.backgroundColor, // Apply errorDialogStyles here
        }}
      >
        <DialogTitle id="error-dialog-title">Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorMessage('')} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container direction="column" spacing={1}>
        <Grid item container spacing={1}>
          <Grid item xs={2}>
            <TextField
              fullWidth
              size="small"
              label="Id"
              value={id}
              onChange={(e) => setId(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              size="small"
              label="Event name"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              fullWidth
              size="small"
              value={countryId}
              onChange={(_, newVal) => {
                setCountryId(newVal);
              }}
              getOptionLabel={(option) => `${option.name}`}
              options={Countries}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  inputRef={countryRef}
                  {...params}
                  label="Country"
                  placeholder="Country"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </Grid>
          {isInternal && (
            <Grid item xs={2}>
              <Select
                fullWidth
                size="small"
                native
                label="Branch Office"
                value={branchOfficeId}
                onChange={(e) => {
                  setBranchOfficeId(e.target.value);
                }}
              >
                <option value={0}>All Branches</option>
                {offices.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </Grid>
          )}
          <Grid item xs={2}>
            <DatePicker
              autoComplete="off"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              error={true}
              selected={(eventDate && new Date(eventDate)) || null}
              onChange={(newVal) => {
                if (newVal) {
                  newVal = addHours(startOfDay(newVal), 12);
                }
                setEventDate(newVal);
              }}
              customInput={
                <TextField
                  label="Event Date"
                  fullWidth
                  value={eventDate}
                  variant="outlined"
                  size="small"
                />
              }
            />
          </Grid>
          <Grid item justify="flex-end" className=" ml-auto " xs={1.6}>
            <Button
              variant="contained"
              size="small"
              onClick={handleAddEvent}
              startIcon={<PersonAddAltIcon />}
              className="bg-gradient-to-r from-indigo-700 to-purple-700 hover:scale-105 transform transition-all duration-300 rounded-full text-white font-bold px-6 py-2 shadow-md"
            >
              Add Event
            </Button>
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item xs={1.6}>
            <DatePicker
              autoComplete="off"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              error={true}
              selected={(start && new Date(start)) || null}
              onChange={(newVal) => {
                if (newVal) {
                  newVal = addHours(startOfDay(newVal), 12);
                }
                setStart(newVal);
              }}
              customInput={
                <TextField
                  label="Start Date"
                  fullWidth
                  value={start}
                  variant="outlined"
                  size="small"
                />
              }
            />
          </Grid>
          <Grid item xs={1.6}>
            <DatePicker
              autoComplete="off"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              error={true}
              selected={(end && new Date(end)) || null}
              onChange={(newVal) => {
                if (newVal) {
                  newVal = addHours(startOfDay(newVal), 12);
                }
                setEnd(newVal);
              }}
              customInput={
                <TextField
                  label="End Date"
                  fullWidth
                  value={end}
                  variant="outlined"
                  size="small"
                />
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Select
              fullWidth
              size="small"
              native
              loading={loading}
              label="Event Category"
              value={eventCategoryId}
              onChange={(e) => {
                setEventCategoryId(e.target.value);
              }}
            >
              <option value={0}>Event Category</option>
              {EVENT_CATEGORIES.map((item) => (
                <option key={item.id} value={item.id}>
                  {item?.type}
                </option>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2}>
            <Select
              fullWidth
              size="small"
              native
              loading={loading}
              label="Event type"
              value={eventType}
              onChange={(e) => {
                setEventType(e.target.value);
              }}
            >
              {/* <option value={0}>Event Category</option>
                {EVENT_CATEGORIES.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.type}
                  </option>
                ))} */}
              <option value={0}>Event Type</option>
              <option value={2}>Event Active</option>
              <option value={1}>Event Inactive</option>
            </Select>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              onClick={getData}
              variant="contained"
              className="bg-sky-400 hover:bg-sky-500"
            >
              Apply Filters
            </Button>
          </Grid>
          <Grid item xs={1.7}>
            <Button fullWidth onClick={resetFilter} variant="outlined">
              Reset Filters
            </Button>
          </Grid>
          {/* {isInternal && (
            <Grid item xs={1.5} container justifyContent={'flex-end'}>
              <Grid item>
                <Button
                  fullWidth
                  onClick={() => setBulkUpdate(!bulkUpdate)}
                  variant="text"
                >
                  {bulkUpdate ? 'Disable Bulk Update' : 'Enable Bulk Update'}
                </Button>
              </Grid>
              {bulkUpdate && (
                <Grid item>
                  <IconButton
                    onClick={() => {
                      if (selectionModel.length > 0) {
                        showEditOptions(null);
                      }
                    }}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          )} */}
        </Grid>

        <Grid item style={{ height: 730, width: '100%' }}>
          <DataGridPro
            pagination
            rows={events}
            columns={eventColumns}
            loading={loading}
            density="compact"
            rowHeight={90}
            components={{
              Toolbar: () =>
                CustomToolbar(permissions.CanManageEvents, isInternal),
            }}
            onCellClick={(params, event) => {
              event.defaultMuiPrevented = true;
              if (params.colDef.field === 'eventName') {
                window.open(`/event/${params.row.id}/eventleadlist`, '_blank');
              }
            }}
            checkboxSelection={isInternal && bulkUpdate}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel); //onRowSelection use for datagridpro v6 and onselection is use for current  datagrid
            }}
            selectionModel={selectionModel}
          />
        </Grid>
      </Grid>
      {ready && (
        <LeadEditEvents
          bulkUpdate={bulkUpdate}
          leadEvent={bulkUpdate ? null : event}
          users={users.filter((item) => item.isDisabled == false)}
          cancel={setReady}
          action={actionSelectedUser}
          role={role}
        />
      )}
    </>
  ) : (
    <LoadingView />
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    users: state.users,
    agents: state.agents,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
    agentId: ownProps && ownProps.agentId ? ownProps.agentId : 0,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadEvents);
