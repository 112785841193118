import React, { useState, useCallback, useEffect } from 'react';
import Agent from '../../../api/agent';
import { INTAKES, intakeYearList, STUDENTSOURCES } from '../../../Util/Constants';
import InputTags from './InputTags';
import { TextField } from '@mui/material';

const statusTypes = [
  { id: 1, type: 'Lead Organic', key: 'organicLead' },
  { id: 2, type: 'Lead Paid', key: 'paidLead' },
  { id: 3, type: 'Total Lead', key: 'totalLead' },
  { id: 4, type: 'Potential Lead', key: 'potentialLead' },
  { id: 5, type: 'Qualified', key: 'qualified' },
  { id: 6, type: 'Applied', key: 'applied' },
  { id: 7, type: 'Applied Ratio', key: 'appliedRatio', isRatio: true },
  { id: 8, type: 'Conditional Offer', key: 'conditionalOffer' },
  { id: 9, type: 'Unconditional Offer', key: 'unconditionalOffer' },
  { id: 10, type: 'Total Offer', key: 'totalOffer' },
  { id: 11, type: 'Offer Ratio', key: 'offerRatio', isRatio: true },
  { id: 12, type: 'Rejection', key: 'rejection' },
  { id: 13, type: 'Rejection Ratio', key: 'rejectionRatio', isRatio: true },
  { id: 14, type: 'Deposit', key: 'deposite' },
  { id: 15, type: 'Num of CAS', key: 'numOfCAS' },
  { id: 16, type: 'Enrolled', key: 'enrolled' },
  { id: 17, type: 'Enrolled Ratio', key: 'enrolledRatio', isRatio: true },
];

const staticTargets = {
  organicLead: 100,
  paidLead: 100,
  totalLead: 200,
  potentialLead: 50,
  qualified: 80,
  applied: 60,
  appliedRatio: 30,
  conditionalOffer: 40,
  unconditionalOffer: 30,
  totalOffer: 70,
  offerRatio: 70,
  rejection: 10,
  rejectionRatio: 10,
  deposite: 50,
  numOfCAS: 40,
  enrolled: 30,
  enrolledRatio: 50,
};

const GlobalSalesProgressReport = () => {
  const [intakeYears, setIntakeYears] = useState([]);
  const [intakeMonths, setIntakeMonths] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [sources, setSources] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await Agent.Cache.getBranchOffices();
        if (Array.isArray(response)) {
          setBranches(response.map(branch => ({ id: branch.id.toString(), label: branch.name })));
        } else {
          console.error('Unexpected response format for branch offices:', response);
        }
      } catch (err) {
        console.error('Error fetching branch offices:', err);
        setError('Failed to fetch branch offices. Please try again later.');
      }
    };

    fetchBranches();
  }, []);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await Agent.Report.globalSalesProgress({
        intakeYears,
        intakeMonths,
        startDate,
        endDate,
        sources,
        branches: selectedBranches.map(branchId => parseInt(branchId, 10))
      });
      if (Array.isArray(response) && response.length > 0) {
        setData(response);
      } else {
        setError('No data available for the selected criteria.');
      }
    } catch (err) {
      setError('Failed to fetch data. Please try again.');
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  }, [intakeYears, intakeMonths, startDate, endDate, sources, selectedBranches]);

  const handleGenerateReport = () => {
    fetchData();
  };

  const handleResetFilters = () => {
    setIntakeYears([]);
    setIntakeMonths([]);
    setStartDate('');
    setEndDate('');
    setSources([]);
    setSelectedBranches([]);
    setData([]);
    setError(null);
  };

  const formatValue = (value, isRatio) => {
    if (value === undefined || value === null) return '-';
    if (isRatio) {
      return `${Number(value).toFixed(2)}%`;
    }
    return value.toString();
  };

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
      <div className="bg-white p-6 rounded-lg shadow mb-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Global Sales Progress Report</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <InputTags
            label="Intake Years"
            value={intakeYears}
            onChange={setIntakeYears}
            options={intakeYearList.map(year => ({ id: year.year.toString(), label: year.year.toString() }))}
            placeholder="Select intake years"
          />
          <InputTags
            label="Intake Months"
            value={intakeMonths}
            onChange={setIntakeMonths}
            options={[{ id: '', label: 'All Months' }, ...INTAKES.map(month => ({ id: month.id.toString(), label: month.type }))]}
            placeholder="Select intake months"
          />
          <InputTags
            label="Sources"
            value={sources}
            onChange={setSources}
            options={STUDENTSOURCES.map(source => ({ id: source.id.toString(), label: source.type }))}
            placeholder="Select sources"
          />
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
            size="small"
            className="bg-white"
          />
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
            size="small"
            className="bg-white"
          />
          <InputTags
            label="Branches"
            value={selectedBranches}
            onChange={setSelectedBranches}
            options={branches}
            placeholder="Select branches"
          />
        </div>
        <div className="mt-4 flex justify-end space-x-4">
          <button
            onClick={handleResetFilters}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 transition-colors"
          >
            Reset Filters
          </button>
          <button
            onClick={handleGenerateReport}
            disabled={loading}
            className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 transition-colors ${
              loading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {loading ? 'Generating...' : 'Generate Report'}
          </button>
        </div>
      </div>

      {error && <p className="text-center py-4 text-red-600 bg-red-100 rounded-lg">{error}</p>}
      {!loading && !error && data.length > 0 && (
        <div className="bg-white rounded-lg shadow overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-800 text-white">
                <th className="py-3 px-4 text-left sticky left-0 z-20 bg-gray-800">Status Type</th>
                {INTAKES.map((month) => (
                  <th key={month.id} className="py-3 px-4 text-center">{month.type}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {statusTypes.map((status, index) => (
                <tr key={status.id} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                  <td className="py-2 px-4 font-medium sticky left-0 z-10 border-r border-gray-200" style={{backgroundColor: index % 2 === 0 ? '#F9FAFB' : '#FFFFFF'}}>
                    {status.type}
                  </td>
                  {INTAKES.map((month) => {
                    const monthData = data.find(d => d.month === month.id) || {};
                    const achievement = monthData[status.key];
                    const target = staticTargets[status.key] || '-';
                    return (
                      <td key={`${status.id}-${month.id}`} className="py-2 px-4 text-center border-r border-gray-200">
                        <div className="flex justify-between items-center space-x-2">
                          <div className="flex-1 bg-red-50 rounded p-1">
                            <div className="text-xs font-semibold text-red-700">Target</div>
                            <div className="font-bold text-red-600">{formatValue(target, status.isRatio)}</div>
                          </div>
                          <div className="flex-1 bg-green-50 rounded p-1">
                            <div className="text-xs font-semibold text-green-700">Achieve</div>
                            <div className="font-bold text-green-600">{formatValue(achievement, status.isRatio)}</div>
                          </div>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default GlobalSalesProgressReport;

