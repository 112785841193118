import { TableBar } from '@mui/icons-material';
import React from 'react';
import { useNavigate } from 'react-router-dom';


const ReportingPanel = () => {
  const navigate = useNavigate();

  const reportTypes = [
    {
      title: 'Global Sales Progress',
      description: 'View and analyze global sales progress.',
      icon: TableBar,
      path: '/globalsalesreport',
      color: 'text-blue-500'
    },
    // {
    //   title: 'Revenue Analysis',
    //   description: 'Analyze revenue trends and patterns.',
    //   icon: Trading,
    //   path: '/revenue-analysis',
    //   color: 'text-green-500'
    // },

  ];

  return (
    <div className="bg-gradient-to-b from-sky-200 to-sky-300 min-h-screen">
      <div className="py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-3xl font-bold text-center mb-12 text-gray-800">
            Reporting Panel
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {reportTypes.map((report, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between items-center transition-all duration-300 hover:shadow-lg hover:scale-105">
                <report.icon className={`w-16 h-16 ${report.color} mb-4`} />
                <h2 className="text-xl font-semibold text-gray-800 mb-4 text-center">
                  {report.title}
                </h2>
                <p className="text-base text-gray-700 text-center mb-6">
                  {report.description}
                </p>
                <button
                  onClick={() => navigate(report.path)}
                  className={`w-full py-2 px-4 bg-${report.color.split('-')[1]}-500 text-white rounded-md hover:bg-${report.color.split('-')[1]}-600 focus:outline-none focus:ring-2 focus:ring-${report.color.split('-')[1]}-500 focus:ring-opacity-50 transition-colors duration-300`}
                >
                  View Report
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportingPanel;

