import React, { useState, useEffect, useRef } from 'react';
import { Chip, TextField, Autocomplete } from '@mui/material';

const InputTags = ({ label, value, onChange, options, placeholder }) => {
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event, newValue) => {
    onChange(newValue.map(item => item.id));
  };

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option) => option.label}
      value={options.filter(option => value.includes(option.id))}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
          fullWidth
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            label={option.label}
            {...getTagProps({ index })}
            key={option.id}
          />
        ))
      }
      size="small"
    />
  );
};

export default InputTags;

