import React, { useContext, useEffect, useState } from 'react';
import { Tab, Tabs, Box } from '@mui/material';
import LoadingView from '../Common/Loading/LoadingView';
import General from './General/General';
import Whatsapp from './Whatsapp/Whatsapp';
import Agent from '../../api/agent';
import UserContext from '../../context/user-context';
import { connect } from 'react-redux';

const Notifications = ({ role,isInternal }) => {
  let userContext = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [reload, setReload] = useState(false);
  console.log('role',role);
  useEffect(() => {
    Agent.Summary.getNotifications()
      .then((notifications) => {
        var notificationsTemp=notifications.filter((item)=>!item.isRead);
        userContext = {
          ...userContext,
          notificationCount: notificationsTemp.length,
          notifications,
        };
        userContext.setUserContext(userContext);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [reload]);
  const successDialogStyles = {
    backgroundColor: 'bg-green-400',
  };
  const handleTabChange = (e, newVal) => {
    setTab(newVal);
  };

  return (
    <>   
      {loading ? (
        <div style={{ marginTop: '0px', fontWeight: 'lighter' }}>
          <LoadingView />
        </div>
      ) : (
        <div>
          {/* <h3 style={{ fontWeight: 'bolder',marginTop:'-1%'}}>Reports and Analytics </h3> */}
          <Box>
            <Box>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: '#0081C9',
                    height: '5px',
                    borderRadius: '50px',
                  },
                }}
              >
                {tabItems.map((item, index) => {
                  if (role.isAgent == true || role.isAgentStaff == true) {
                    if (index == 0) {
                      return (
                        <Tab
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '1em',
                          }}
                          label={item}
                          key={index}
                        />
                      );
                    } 
                  }else {
                    return (
                      <Tab
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '1em',
                        }}
                        label={item}
                        key={index}
                      />
                    );
                  }
                })}
              </Tabs>

              <Box>
                <TabPanel value={tab} index={0}>
                  <General role={role} setReload={setReload} isInternal={isInternal}/>
                </TabPanel>
                <TabPanel value={tab} index={1}>
                  <Whatsapp role={role} setReload={setReload} isInternal={isInternal}/>
                </TabPanel>
              
              </Box>
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};

const TabPanel = (props) => {
  const { children, value, index } = props;

  return <div>{value === index && <Box>{children}</Box>}</div>;
};

const tabItems = ['General Notifications','WhatsApp Notifications'];

const mapStateToProps = (state) => {
  return {
    role: state.auth.role,
    isInternal: state.auth.isInternal,
  };
};

export default connect(mapStateToProps, null)(Notifications);
